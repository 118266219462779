/**
 * 代理详情
 */
import { orderReject } from '@/service/order/index.js'
import { Dialog, Toast } from 'vant';
import EmptyList from '@/components/common/empty-list/index.vue'
import { mapState } from 'vuex'
import ReviewDetail from '@/components/auth/review-detail/index.vue'

export default {
  name: 'order-review-detail',
  components: { EmptyList, ReviewDetail },
  methods: {
    //  同意订单
    sure() {
      this.$router.push({
        name: 'order-logistics',
        query: {
          row: JSON.stringify(this.row)
        }
      })
    },
    // 拒绝订单
    cancel() {
      Dialog.confirm({
        message: `是否拒绝${this.row.userName}的订单？`
      }).then(async () => {
        const data = await orderReject({ operateUserId: this.user.id, id: this.row.id });
        Toast(data);
        //修改当前状态
        // this.row.state = 9;
        this.$router.go(-1);
      }).catch((e) => {
        console.log(e);
      });
    },

  },

  data() {

    return {
      row: {},//详情的信息
      showDetail: false,//是否展示个人信息的弹窗
      reason: '',
      rejectShow: false,//reject弹出层
      showList: [],
      finished: false,
      offset: 0,//页数，0开始
      limit: 10,//每页的调数
      loading: false,
      systemList: [],//用户的升级列表
      canUpgrade: false, // 能否给当前代理升级
    }
  },
  mounted() {
    const { row } = this.$route.query;
    this.row = JSON.parse(row);
  },
  computed: {
    ...mapState({
      user: state => state.user.user // 我的ID
    })
  },


}
