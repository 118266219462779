/*
 * @Author: fengyanjing
 * @Date: 2020-05-16 13:44:57
 * @Description:
 * @FilePath: /frontend/src/components/auth/review-detail/index.js
 */
import { Dialog } from 'vant';
import { ISQINGBULUO } from '@/utils/common.js';

export default {
    name: 'review-detail',

    props: {
        // 申请信息
        info: {
            type: Object,
            default: {},
        },
        showmaininfo: {
            type: Number,
            default: 0,
        },
        tab: {
            // 0是新增代理，1是升级代理
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            text: '', //拒绝的文字
            // 展示的信息

            localshowinfo: 0, //0：用户信息，1：同意弹窗。2：拒绝输入框。3：拒绝弹窗，4：无
        };
    },

    watch: {
        showmaininfo(newvalue, oldvalue) {
            this.localshowinfo = newvalue;
            // 新增的没有user。formlevelname。所以隐藏
        },
    },

    methods: {
        //  判断是不是轻部落
        ISQINGBULUO,
        //关闭自身
        closeself() {
            this.$emit('closeDetail');
        },
        // 拒绝申请
        refuse() {
            this.localshowinfo = 2;
        },
        // 同意申请
        agree() {
            Dialog.confirm({
                message: `是否同意${this.info.user.name}审核？`,
            })
                .then(async () => {
                    await this.$emit('agree', this.info);
                })
                .catch(() => {});
        },
        // 拒绝的取消
        refusecancel() {
            this.localshowinfo = 0;
            this.text = ''; //清空
        },
        //拒绝的确定
        commitrefuse() {
            Dialog.confirm({
                message: `是否拒绝${this.info.user.name}审核？`,
            })
                .then(async () => {
                    await this.$emit('reject', { item: this.info, text: this.text });
                    this.text = ''; //清空
                })
                .catch(() => {
                    this.text = ''; //清空
                });
        },
    },
};
